.Header { max-width: 1000px; padding: 1rem; margin: 0 auto; }
.Header { margin-top: 4rem; margin-bottom: 3rem; color: rgba(255, 255, 255, .9); text-shadow: 1px 1px 30px black; }
.Header > h1 { font-size: 15vmin; font-weight: 600; }
.Header > div.tagline { font-size: 1.1rem; margin-left: .4rem; margin-bottom: 1rem; }
.Header > div.tagline > p { margin: .3rem 0; }
.Value { font-size: 2rem; }
.Love { background: #fff; color: #000; padding: 2rem 0 6rem; }
.Love > header { max-width: 1000px; padding: 1rem; margin: 0 auto; }
.Love > header { font-family: Montserrat; font-size: 2.5rem; font-weight: 600; text-align: center; }
.Love > section { max-width: 1000px; padding: 1rem; margin: 0 auto; }
.Love > section { display: flex; flex-flow: wrap; justify-content: space-around; }
.Love > section > div { max-width: 290px; }
.Love > section > div > header { height: 180px; margin-bottom: 1rem; background-color: #aaa; }
.Love > section > div > header.-code { background-image: url(images/scuttleverse.png); }
.Love > section > div > header.-humans { background-image: url(images/humans.png); }
.Love > section > div > header.-process { background-image: url(images/process.png); }
.Love > section > div > header > h3 { color: white; font-size: 2rem; font-weight: 600; padding-top: 0.4rem; padding-left: 10px; }
.Work { background: #fff; color: #000; padding: 2rem 0 6rem; }
.Work > header { max-width: 1000px; padding: 1rem; margin: 0 auto; }
.Work > header { font-family: Montserrat; font-size: 2.5rem; font-weight: 600; text-align: center; }
.Work > section { max-width: 1000px; padding: 1rem; margin: 0 auto; }
.Work > section { display: flex; flex-flow: wrap; justify-content: space-around; }
.Work { background-color: #000; color: #fff; }
.Work > section { padding-top: 3rem; }
.Work > section > div.projects { display: flex; flex-flow: wrap; justify-content: space-around; }
.Work > section > div.projects > div.project { min-width: 110px; margin-bottom: 2rem; display: flex; flex-direction: column; align-items: center; }
.Work > section > div.projects > div.project > a img { width: 140px; height: 140px; }
.Work > section > div.projects > div.project > ul { margin-left: -2rem; }
.Services { background: #fff; color: #000; padding: 2rem 0 6rem; }
.Services > header { max-width: 1000px; padding: 1rem; margin: 0 auto; }
.Services > header { font-family: Montserrat; font-size: 2.5rem; font-weight: 600; text-align: center; }
.Services > section { max-width: 1000px; padding: 1rem; margin: 0 auto; }
.Services > section { display: flex; flex-flow: wrap; justify-content: space-around; }
.Services > section { display: inherit; }
.Services > section > h3 { font-size: 2rem; }
.About { background: #fff; color: #000; padding: 2rem 0 6rem; }
.About > header { max-width: 1000px; padding: 1rem; margin: 0 auto; }
.About > header { font-family: Montserrat; font-size: 2.5rem; font-weight: 600; text-align: center; }
.About > section { max-width: 1000px; padding: 1rem; margin: 0 auto; }
.About > section { display: flex; flex-flow: wrap; justify-content: space-around; }
.About { background-color: #000; color: #fff; }
.About > section { padding-top: 3rem; justify-content: center; }
.About > section > div { display: flex; flex-direction: column; align-items: center; }
.About > section > div > img { width: 200px; height: 200px; margin: 0rem 0.5rem; margin-bottom: 1rem; }
.About > section > div > div.social-links { flex-grow: 1; display: flex; align-items: flex-end; justify-content: center; width: 100%; }
.About > section > div > div.social-links > a > img { height: 36px; margin-bottom: 1rem; }
.Footer { background: #fff; color: #000; padding: 2rem 0 6rem; }
.Footer > header { max-width: 1000px; padding: 1rem; margin: 0 auto; }
.Footer > header { font-family: Montserrat; font-size: 2.5rem; font-weight: 600; text-align: center; }
.Footer > section { max-width: 1000px; padding: 1rem; margin: 0 auto; }
.Footer > section { display: flex; flex-flow: wrap; justify-content: space-around; }
.Footer > section p { font-size: 1.4rem; text-align: center; }
.Footer > section p > a { font-size: 9vmin; }
body { background: url("images/radiolarians.jpg") no-repeat; background-position: calc(50% + 300px) -12rem; background-color: #000; font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; font-size: 15px; margin: 0; }
h1, h2, h3, h4 { font-family: Montserrat; margin-bottom: .5rem; font-weight: 500; }
a { color: #000; }
main { display: flex; flex-direction: column; justify-content: center; }
ul { margin-left: -2rem; }
@font-face { font-family: 'Open Sans'; }
